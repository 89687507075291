import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ChangeDetectorRef, Component, ElementRef, NgZone, OnInit} from "@angular/core";
import {ClientAppConfig, ClientNanosite, IGlue, IRPCExecutor, NanositeApp} from "@nanosites";
import {findChildWithClassName} from "@utility";
import {firstValueFrom} from "rxjs";
import {environment} from "../environments/environment";
import * as Sentry from "@sentry/angular";
import {BrowserTracing} from "@sentry/tracing";

interface IClientSessionData extends ClientAppConfig {
	block: ClientNanosite,
	glue: IGlue,
	sessionId: number,
	redirect: false
}

interface IAutoSessionData {
	sessionId: string;
}

type IResponse<T> = { ok: true, data: T } | { ok: false, error: string };

class ClientRPCExecutor implements IRPCExecutor {

	constructor(private readonly sessionId: number, private readonly http: HttpClient) {

	}

	private async postAny(backend: string, action: string, payload: any = {}): Promise<any> {

		const signedPayload = {
			"action": "invoke",
			"fn": action,
			...payload
		};

		const result = firstValueFrom(this.http.post(backend, signedPayload));

		return new Promise<any>(async (resolve, reject) => {

			let error = null;
			try {
				const response = await result as any;
				if (response.ok) {
					resolve(response.data);
				} else {
					error = response.error;
				}
			} catch (e) {
				error = e;
			}

			if (error != null)
				reject(error);
		});
	}

	async executeRPC(fn: string, params: any[]): Promise<any> {
		return await this.postAny(environment.backend + "rpc", fn, {
			"sessionId": this.sessionId,
			"params": params
		});
	}
}

export function isValidSessionId(sessionId: any) {

	if (!sessionId)
		return false;

	if (typeof sessionId === "string") {
		sessionId = parseInt(sessionId);
		if (isNaN(sessionId))
			return false;
	}

	return sessionId > 0;
}


type SessionDataResponse = IClientSessionData | {
	redirect: true,
	auto: string
}

@Component({
	selector: "nanosite-client",
	templateUrl: "./client.component.html",
	styleUrls: ["./client.component.less"]
})
export class ClientComponent implements OnInit {

	loading: boolean = true;
	error: boolean = false;
	errorMessage: string = "It appears that something has gone wrong with your session, please try again";

	private _nanoApp: NanositeApp = null;

	constructor(
		private readonly elem: ElementRef,
		private readonly zone: NgZone,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly http: HttpClient) {

	}

	async getSessionData(sessionId: any | undefined): Promise<SessionDataResponse | null> {

		const payload = {
			"action": "get-session-data",
			"source": window.location.origin + window.location.pathname,
		};

		if (isValidSessionId(sessionId)) {
			payload["sessionId"] = sessionId;
		} else {
			// The session id can be inferred by the backend in some cases - let's still try.
		}

		let data: IResponse<SessionDataResponse>;
		try {
			const prefetch = window["$nanosite"];
			if (prefetch) {
				if (prefetch.sessionId === sessionId) {
					data = await this.zone.run(async () => {
						const fetchResponse: Response = await prefetch.data;
						return await fetchResponse.json();
					});
				}
			}
		} catch (e) {
			console.error(e);
		}

		if (!data) {
			const post = this.http.post(environment.backend + "block", payload);
			data = await firstValueFrom(post) as IResponse<SessionDataResponse>;
		}

		if (data.ok == false) {
			throw new Error(data.error);
		}


		const session = data.data;
		if (session.redirect === false) {

			session.glue = JSON.parse(session.glue as any as string || "");

			if (!session.settings) {
				session.settings = {};
			}

			if (!session.inputs) {
				session.inputs = {};
			}
		}
		return session;
	}

	async getAutoSession(auto: any): Promise<string> {

		const payload = {
			"action": "get-auto-session",
			"auto": auto,
			"params": Object.fromEntries(new URLSearchParams(window.location.search) as any)
		};

		let data: IResponse<IAutoSessionData> | null = null;
		try {
			const prefetch = window["$auto"];
			if (prefetch) {
				console.log("Prefetching...");
				data = await this.zone.run(async () => {
					const fetchResponse: Response = await prefetch;
					return await fetchResponse.json();
				});
			} else {
				data = await firstValueFrom(this.http.post(environment.backend + "manage", payload)) as IResponse<IAutoSessionData>;
			}
		} catch (e) {
			console.error(e);
			if (e instanceof HttpErrorResponse)
				throw new Error(e.error.error);
			throw new Error(e);
		}

		if (data) {
			if (data.ok == false) {
				throw new Error(data.error);
			}
			return data.data.sessionId;
		}

		throw new Error("No session id found");
	}

	private async loadAutoSession(auto: string) {
		Sentry.setTag("mode", "auto");
		Sentry.setTag("autoSessionId", auto);

		try {
			const sessionId = await this.getAutoSession(auto);
			Sentry.setTag("sessionId", sessionId);

			// redirect self to same url, but with new search params
			const newSearch = new URLSearchParams();
			newSearch.set("session", sessionId);

			const newUrl = new URL(window.location.href);
			newUrl.search = newSearch.toString();
			window.location.href = newUrl.href;
		} catch (e) {
			this.error = true;
			this.loading = false;
			this.errorMessage = e.message;
		}
	}

	async ngOnInit() {

		Sentry.init({
			dsn: "https://ee41a8b76f6348d4b45799fb5081a04a@o1372027.ingest.sentry.io/4504691421741056",
			integrations: [
				new BrowserTracing({
					routingInstrumentation: Sentry.routingInstrumentation,
				}),
			],
			tracesSampleRate: 1.0,
		});

		const urlParams = new URLSearchParams(window.location.search);
		const auto = urlParams.get("auto");

		if (auto) {
			return await this.loadAutoSession(auto);
		}

		let sessionId: any = urlParams.get("session") as any;
		if (sessionId)
			Sentry.setTag("mode", "sessionId");

		let sessionData: SessionDataResponse | null = null;
		try {
			sessionData = await this.getSessionData(sessionId);
		} catch (e) {
			console.error("Unable to get session data", e);
			this.error = true;
			this.loading = false;
			return;
		}

		if (sessionData == null) {
			console.error("Session data is null");
			this.error = true;
			this.loading = false;
			return;
		}

		if (sessionData.redirect === true) {
			return await this.loadAutoSession(sessionData.auto);
		}

		const rpc = new ClientRPCExecutor(sessionId, this.http);
		const target = findChildWithClassName(this.elem.nativeElement, "render-container") as HTMLElement;

		this._nanoApp = new NanositeApp({
			nanosite: sessionData.block,
			glue: sessionData.glue,
			config: sessionData,
			rpcExecutor: rpc,
			host: target,
			onError: (error) => {
				console.error("Nanosite Error", error);
				this.error = true;
				this.loading = false;
			},
			onChanges: () => {
				this.zone.run(() => {
					this.changeDetector.detectChanges();
				});
			},
			reportErrors: true,
		});

		await this._nanoApp.start();
		this.loading = false;
	}

	get rpcInProgress(): boolean {
		if (this._nanoApp)
			return this._nanoApp.working;
		return false;
	}
}
