import {clone, getHashCode, loadScriptDynamically, makeRandomIdentifier} from "@utility";
import * as less from "less";
import {Subscription} from "rxjs";
import {
	AppConfig,
	blockTag,
	BlueprintType,
	ClientAppConfig,
	ClientNanosite,
	IGlue,
	InputType,
	NanoNode,
	Nanosite,
	traverse
} from "./types";
import {IRPCExecutor} from "./interfaces";
import {getNanositeGlobals, NanositeSession} from "./timers";
import {getVueEditorConfig, getVueObject, instantiateVue, VueVM} from "./vueutil";
import * as Sentry from "@sentry/angular";

export type NodeActionOverride = (node: NanoNode) => true | false | null;

export type NanositeError = {
	source: string;
	error: any
};

export type NanositeOptions = {
	nanosite: ClientNanosite;
	host: HTMLElement;
	glue: IGlue;
	rpcExecutor: IRPCExecutor;
	config: AppConfig | ClientAppConfig | null;
	debug?: boolean;
	nodeEnableActionOverride?: NodeActionOverride;
	onError?: (error: NanositeError) => void;
	onChanges?: () => void;
	reportErrors: boolean;
}

const selfClosingTags = {"input": true, "img": true, "br": true, "col": true};

function argumentsToArray(args: IArguments) {
	const array = Array(args.length);
	for (let i = 0; i < args.length; ++i)
		array[i] = args[i];
	return array;
}

function encodeAttribute(name: string): string {
	return `${name}`;
}

function makeIndent(depth: number): string {
	let indent = "\n";
	for (let i = 0; i < depth + 1; ++i) {
		indent += "    ";
	}
	return indent;
}

function getTag(node: NanoNode): [string, boolean] {
	const tag = blockTag(node.htmlType, node);
	return [tag, selfClosingTags.hasOwnProperty(tag)];
}

function validExpression(action: string): boolean {
	return (!!action) && action.trim().length > 0;
}

export function buildNanositeNodeHTML(node: NanoNode, depth: number = 0, debug: boolean = false, nao: NodeActionOverride = null): string {

	let [tag, selfClosing] = getTag(node);
	let attributes: { [tag: string]: string } = {};

	if (node.isContentBlock && tag !== "input") {
		[tag, selfClosing] = ["ckeditor", false];
		attributes[":editor"] = "UPWINTERNAL.EditorController";
		attributes[":config"] = "UPWINTERNAL.EditorConfig";
		attributes["v-model"] = "$$GETNODE('" + node.id + "').contentHtml";
	}

	let html = "<" + tag;

	for (let attributeKey in node.attrs) {
		attributes[attributeKey] = node.attrs[attributeKey];
	}

	if (node.className) {
		attributes["class"] = encodeAttribute(node.className);
	}

	if (debug) {
		attributes["debug-node-id"] = node.id;
	}

	if (validExpression(node.repeatBinding) && validExpression(node.repeatBinding))
		attributes["v-for"] = `${node.repeatBinding} in ${node.repeatAction}`;

	if (validExpression(node.enableAction)) {
		attributes["v-if"] = `${node.enableAction}`;
	}

	if (nao) {
		const value = nao(node);
		if (value === true) {
			attributes["v-if"] = "true";
		} else if (value === false) {
			attributes["v-if"] = "false";
		}
	}

	if (validExpression(node.clickAction)) {
		attributes["v-on:click"] = `${node.clickAction}`;
	}

	if (validExpression(node.classAction)) {
		attributes["v-bind:class"] = node.classAction;
	}

	// compatibility with old style
	if ("placeholder" in node) {
		attributes["placeholder"] = node["placeholder"];
	}

	if (validExpression(node.model)) {
		attributes["v-model"] = node.model;
	}

	for (let attribute in attributes) {
		const value = attributes[attribute];
		html += ` ${attribute}="${value}"`;
	}

	if (!selfClosing) {
		html += ">";
		const indent = makeIndent(depth);

		if (node.text && node.text.length > 0) {
			html += indent + node.text;
		}

		for (let child of node.children) {
			html += indent + buildNanositeNodeHTML(child, depth + 1, debug, nao);
		}

		const ondent = makeIndent(depth - 1);
		html += ondent + "</" + tag + ">";

	} else {
		html += "/>";
	}

	return html;
}

export function getTheme(nanosite: ClientNanosite, config: AppConfig | ClientAppConfig | null | undefined): any {

	let map: { [key: string]: any } = {};
	if (config) {
		if (Array.isArray(config.settings)) {
			for (let setting of config.settings) {
				const settingConfig = nanosite.settings.find(s => s.id === setting.referenceId);
				if (settingConfig)
					map[settingConfig.name] = setting.value;
			}
		} else {
			map = config.settings;
		}
	}

	let styleVariables = {};
	for (let setting of nanosite.settings)
		styleVariables[setting.name] = setting.defaultValueInitializor || "initial";

	for (let setting in map)
		styleVariables[setting] = map[setting] || styleVariables[setting] || "initial";

	return styleVariables;
}


export function getInputs(nanosite: ClientNanosite, config: AppConfig | ClientAppConfig | null | undefined): any {

	if (!config)
		return {};

	if (config.inputs instanceof Array) {
		const inputs = {};

		for (let input of config.inputs) {
			const val = nanosite.variables.find(v => v.id === input.referenceId);

			if (val) {
				if (val.type == InputType.Object) {
					try {
						inputs[val.name] = JSON.parse(input.value);
					} catch (e) {
						inputs[val.name] = {};
					}
				}

				if (val.type == InputType.Number) {
					try {
						inputs[val.name] = parseFloat(input.value);
					} catch (e) {
						inputs[val.name] = 0;
					}
				}

				if (val.type == InputType.String) {
					try {
						inputs[val.name] = input.value;
					} catch (e) {
						inputs[val.name] = "";
					}
				}
			}
		}

		return inputs;
	}

	return config.inputs;
}

export function getPrivateInputs(nanosite: Nanosite, config: AppConfig): any {

	if (!config)
		return {};

	if (config.privateInputs instanceof Array) {
		const inputs = {};

		for (let input of config.privateInputs) {
			const val = nanosite.privateInputs.find(v => v.id === input.referenceId);
			if (val) {
				if (val.type == InputType.Object) {
					try {
						inputs[val.name] = JSON.parse(input.value);
					} catch (e) {
						inputs[val.name] = {};
					}
				}

				if (val.type == InputType.Number) {
					try {
						inputs[val.name] = parseFloat(input.value);
					} catch (e) {
						inputs[val.name] = 0;
					}
				}

				if (val.type == InputType.String) {
					try {
						inputs[val.name] = input.value;
					} catch (e) {
						inputs[val.name] = "";
					}
				}
			}
		}

		return inputs;
	}

	return config.inputs;
}

export class NanositeApp {

	private _css: string | null = null;
	private _cssHash: number | null = null;

	private _disposed: boolean = false;
	private _rpcInFlightCounter = 0;
	private _vm: VueVM | null = null;
	private _targetId: string | undefined = undefined;
	private _vueErrorSubscription: Subscription;
	private _theme: any = undefined;

	constructor(private readonly options: NanositeOptions) {
		if (options.reportErrors) {

		}
	}

	private get config(): AppConfig | ClientAppConfig | null {
		return this.options.config;
	}

	private get nanosite(): ClientNanosite {
		return this.options.nanosite;
	}

	private getTheme(): any {
		if (this._theme === undefined)
			this._theme = getTheme(this.nanosite, this.config);
		return this._theme;
	}

	private getInputs(): any {
		return getInputs(this.nanosite, this.config);
	}

	private getHostClass(): string {
		return `block${this.nanosite.id}`;
	}

	private generateLess() {
		return `.${this.getHostClass()} {
			${this.stitchStyleBlueprints()}
			${this.nanosite.stylesheet}
		}`;
	}

	private createServerInterconnectObject() {

		const that = this;

		const server = {};
		if (!that.options.glue) {
			return server;
		}

		for (let func of that.options.glue.fdefs) {
			server[func.remote] = function () {

				const params = argumentsToArray(arguments);
				return new Promise(async (resolve, reject) => {
					try {

						if (params.length != func.args.length) {
							that.onError("SERVER", `Invalid use of server function '${func.remote}()'. Check parameters.`);
							reject("Need params: " + func.args.join(", "));
							return;
						}

						try {
							that._rpcInFlightCounter += 1;
							that.options?.onChanges();
							const result = await that.options.rpcExecutor.executeRPC(func.remote, params);
							resolve(result);
							return;
						} finally {
							that._rpcInFlightCounter -= 1;

							setTimeout(() => { // This prevents UI jitters between successive loading states.
								that.options?.onChanges();
							}, 1);
						}

					} catch (e) {
						reject(e);
					}
				});
			};
		}

		return server;
	}

	private initScope() {
		return [
			clone(this.getInputs()),
			clone(this.getTheme())
		];
	}

	private async loadExternalLibraries(nanosite: ClientNanosite) {
		const promises: Promise<boolean>[] = [];
		for (let library of nanosite.libraries) {
			promises.push(loadScriptDynamically(library.url));
		}

		try {
			await Promise.all(promises);
		} catch (e) {
			this.onError("SCRIPTS", e);
		}
	}

	private async generateStyles() {
		try {

			const lessCode = this.generateLess();
			const hash = getHashCode(lessCode);

			const theme = this.getTheme();

			if (this._cssHash !== hash) {
				const compilation = await less.render(lessCode, {"globalVars": theme});
				this._css = compilation.css;
				this._cssHash = hash;
			}


			const id = `nano_style_${this.nanosite.id}`;
			const existingStyle = document.getElementById(id);
			if (existingStyle && existingStyle.parentNode) {
				try {
					existingStyle.parentNode.removeChild(existingStyle);
				} catch (e) {
					console.warn("Unable to clear styles from", existingStyle.parentNode);
				}
			}

			const style = document.createElement("style");
			style.id = id;
			style.classList.add("editor-generated-style");
			style.appendChild(document.createTextNode(this._css ?? ""));

			const head = document.head || document.getElementsByTagName("head")[0];
			head.appendChild(style);

		} catch (e) {

			try {
				if (this.options.debug) {
					const lessCode = this.generateLess();
					console.log(lessCode);
				}
			} catch (e) {

			}

			console.error("Unable to generate styles", e);
			this.onError("STYLES", e);
		}
	}

	private async renderAppHtml() {
		const loadPromises = this.loadExternalLibraries(this.nanosite);
		await this.generateStyles();
		await loadPromises;
		this._targetId = makeRandomIdentifier(6);
		const debug = this.options.debug;
		const html = buildNanositeNodeHTML(this.nanosite.root, 0, debug, this.options.nodeEnableActionOverride);
		return `<v-app v-cloak id=${(this._targetId)}>${html}</v-app>`;
	}

	private stitchBlueprints(type: BlueprintType, separator: string): string {

		let code = "";
		if (!this.nanosite.blueprints)
			return code;

		for (let blueprint of this.nanosite.blueprints)
			if (blueprint.type === type)
				code += blueprint.code + separator;

		return code;
	}

	private stitchClientBlueprints(): string {
		return this.stitchBlueprints("client", ";\n");
	}

	private stitchStyleBlueprints(): string {
		return this.stitchBlueprints("style", "\n");
	}

	private setDocumentTitleAndFavicon() {
		setTimeout(() => {
			if (!this.config)
				return;

			document.title = this.config.title ?? this.nanosite.name ?? "Upwire";
			const faviconUrl = this.config.favicon;
			if (faviconUrl) {

				const editorFavicon = document.getElementById("editor-favicon");
				if (editorFavicon)
					editorFavicon.parentNode.removeChild(editorFavicon);

				const link = document.createElement("link");
				link.rel = "shortcut icon";
				link.href = faviconUrl;
				document.head.appendChild(link);
			}
		});
	}

	private async startAppJavascript() {

		if (this._disposed)
			return;

		const appStart = window.performance.now();
		const server = this.createServerInterconnectObject();
		const [inputs, theme] = this.initScope();

		const targetId = this._targetId;
		if (targetId === null || targetId === undefined)
			return;

		if (!document.getElementById(targetId))
			return;

		const js = this.nanosite.js;

		const session: NanositeSession = {
			"inputs": inputs,
			"settings": theme, // TODO (FS-3): REMOVE this deprecated alias
			"theme": theme,
			"$alive": () => !this._disposed,
			"$error": (err) => this.onError("TIMER", err),
		};

		const blueprints = this.stitchClientBlueprints();

		const code = `

			var setTimeout = $$globals.setTimeout;
			var clearTimeout = $$globals.clearTimeout;
			var setInterval = $$globals.setInterval;
			var clearInterval = $$globals.clearInterval;

			var scope = session; // TODO (FS-3): REMOVE scope is old alias for 'session'

			${blueprints};
			${js};

			return {
				'computed': (typeof computed === 'undefined') ? {} : computed,
				'watch': (typeof watch === 'undefined') ? {} : watch,
				'methods': (typeof methods === 'undefined') ? {} : methods,
				'data': (typeof data === 'undefined') ? {} : data,
				'init': (typeof init === 'undefined') ? function(){} : init
			};`;

		let jss = null;
		try {
			jss = Function("session", "server", "$$globals", "Vue", code)
			(session, server, getNanositeGlobals(session, () => this._vm), getVueObject());
		} catch (e) {
			this.onError("APP", e);
			return;
		}

		if (jss == null) {
			return;
		}

		const methods = jss["methods"];
		const data = jss["data"];
		const init = jss["init"];
		const watch = jss["watch"];
		const computed = jss["computed"];

		this._vm = await instantiateVue(targetId, data, methods, watch, computed);
		this._vueErrorSubscription = this._vm.errors.subscribe((err) => {
			this.onError("APP", err);
		});

		let initOk = true;
		try {
			const result = init();

			if (result && result.then) {
				result.catch((e: any) => {
					this.onError("APP", e);
				});
			}
		} catch (e) {
			this.onError("APP", e);
			initOk = false;
		}

		if (!initOk)
			return;

		const vueEditorConfig = getVueEditorConfig();

		data["UPWINTERNAL"] = {
			"EditorController": vueEditorConfig.controller,
			"EditorConfig": vueEditorConfig.options
		};

		data["scope"] = session;

		const nodeMap = traverse(this.nanosite.root);
		methods["$$GETNODE"] = function (nodeId: any) {
			return nodeMap[nodeId];
		};


		const time = window.performance.now() - appStart;
		if (time > 200)
			console.warn(`App Init Took [${time}ms]`);
	}

	onError(source: string, error: any) {
		if (this.options.onError)
			this.options.onError({source, error});
		this.stop();

		try {
			if (this.options.reportErrors) {
				Sentry.captureException(error, {tags: {source}});
			}
		} catch (e) {

		}
	}

	async start(): Promise<void> {

		if (this._disposed)
			return;

		this.setDocumentTitleAndFavicon();

		this.options.host.classList.add(this.getHostClass());
		this.options.host.style.display = "none";
		this.options.host.innerHTML = await this.renderAppHtml();

		return new Promise<void>(resolve => {
			setTimeout(async () => {
				await this.startAppJavascript();
				resolve();
				this.options.host.style.display = "initial";
			});
		});
	}

	get working() {
		return this._rpcInFlightCounter > 0;
	}

	stop() {
		if (this._disposed)
			return;

		this.options.host.classList.remove(`block${this.nanosite.id}`);
		this._disposed = true;

		if (this._vueErrorSubscription)
			this._vueErrorSubscription.unsubscribe();

		if (this._vm)
			this._vm.dispose();
		this.options.host.innerHTML = "";
	}
}
